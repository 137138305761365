<template>
    <div>
        <ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
        <div :class="$t('cfds.lang')">
            <div>
                <div class="t-sub1 index-cfds">
                    <h1 class="p1 " :class="$t('cfds.lang') + '-title'">{{ $t('cfds.指数差价合约') }}</h1>
                    <p class="p2">{{ $t('cfds.无需持有基础资产') }}<br />{{ $t('cfds.掌控全球机遇') }}</p>
                    <a :href="$store.state.khUrl" class="btn">{{ $t('cfds.即刻开户') }}
                        <span class="iconfont icon-chevron-right"></span>
                    </a>
                </div>

                <div class="session-1 section-box">
                    <div class="session-title " :class="$t('cfds.lang') + '-title'">
                        {{ $t('cfds.美股指数差价合约') }}
                    </div>
                    <div class="session-info">
                        {{ $t('cfds.点差大幅度降低') }}

                    </div>
                    <div class="content">
                        <div class="mobile">
                            <table class="m-table" align="center" valign="middle" cellspacing="1">
                                <tbody>
                                    <tr>
                                        <td class="left" colspan="3">
                                            <span class="m-table-td-b">U30USD</span><br />
                                            {{ $t('cfds.美国道琼斯工业平均指数') }}
                                        </td>
                                        <td class="bg-light" rowspan="3">
                                            <span class="m-table-td-l-1">{{ $t('cfds.降幅') }}</span><br />
                                            <span class="iconfont icon-down2"></span><br />
                                            <span class="m-table-td-l-2">-<span class="number"
                                                    id="number11">70</span>%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('cfds.亚洲时间') }}</td>
                                        <td>{{ $t('cfds.欧洲时间') }}</td>
                                        <td>{{ $t('cfds.美国时间') }}</td>
                                    </tr>
                                    <tr>
                                        <td>1.30</td>
                                        <td>0.30</td>
                                        <td>0.70</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="m-table" align="center" valign="middle" cellspacing="1">
                                <tbody>
                                    <tr>
                                        <td class="left" colspan="3">
                                            <span class="m-table-td-b">SPXUSD</span><br />
                                            {{ $t('cfds.美国标准普尔500指数') }}
                                        </td>
                                        <td class="bg-light" rowspan="3">
                                            <span class="m-table-td-l-1">{{ $t('cfds.降幅') }}</span><br />
                                            <span class="iconfont icon-down2"></span><br />
                                            <span class="m-table-td-l-2">-<span class="number"
                                                    id="number12">64</span>%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('cfds.亚洲时间') }}</td>
                                        <td>{{ $t('cfds.欧洲时间') }}</td>
                                        <td>{{ $t('cfds.美国时间') }}</td>
                                    </tr>
                                    <tr>
                                        <td>1.30</td>
                                        <td>0.31</td>
                                        <td>0.70</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="m-table" align="center" valign="middle" cellspacing="1">
                                <tbody>
                                    <tr>
                                        <td class="left" colspan="3">
                                            <span class="m-table-td-b">NASUSD</span><br />
                                            {{ $t('cfds.美国纳斯达克100指数') }}
                                        </td>
                                        <td class="bg-light" rowspan="3">
                                            <span class="m-table-td-l-1">{{ $t('cfds.降幅') }}</span><br />
                                            <span class="iconfont icon-down2"></span><br />
                                            <span class="m-table-td-l-2">-<span class="number"
                                                    id="number13">85</span>%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('cfds.亚洲时间') }}</td>
                                        <td>{{ $t('cfds.欧洲时间') }}</td>
                                        <td>{{ $t('cfds.美国时间') }}</td>
                                    </tr>
                                    <tr>
                                        <td>1.00</td>
                                        <td>0.31</td>
                                        <td>0.70</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="m-table" align="center" valign="middle" cellspacing="1">
                                <tbody>
                                    <tr>
                                        <td class="left" colspan="3">
                                            <span class="m-table-td-b">HSIHKD</span><br />
                                            {{ $t('cfds.香港恒生指数') }}
                                        </td>
                                        <td class="bg-light" rowspan="3">
                                            <span class="m-table-td-l-1">{{ $t('cfds.降幅') }}</span><br />
                                            <span class="iconfont icon-down2"></span><br />
                                            <span class="m-table-td-l-2">-<span class="number"
                                                    id="number14">55</span>%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('cfds.亚洲时间') }}</td>
                                        <td>{{ $t('cfds.欧洲时间') }}</td>
                                        <td>{{ $t('cfds.美国时间') }}</td>
                                    </tr>
                                    <tr>
                                        <td>6.50+</td>
                                        <td>6.50</td>
                                        <td>6.50</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="m-table" align="center" valign="middle" cellspacing="1">
                                <tbody>
                                    <tr>
                                        <td class="left" colspan="3">
                                            <span class="m-table-td-b">CNIUSD</span><br />
                                            {{ $t('cfds.中国A50指数') }}
                                        </td>
                                        <td class="bg-light" rowspan="3">
                                            <span class="m-table-td-l-1">{{ $t('cfds.降幅') }}</span><br />
                                            <span class="iconfont icon-down2"></span><br />
                                            <span class="m-table-td-l-2">-<span class="number"
                                                    id="number15">14</span>%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('cfds.亚洲时间') }}</td>
                                        <td>{{ $t('cfds.欧洲时间') }}</td>
                                        <td>{{ $t('cfds.美国时间') }}</td>
                                    </tr>
                                    <tr>
                                        <td>6.00+</td>
                                        <td>6.00</td>
                                        <td>6.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="pc">
                            <table class="pc-table" align="center" valign="middle" cellspacing="1">
                                <thead>
                                    <tr>
                                        <th rowspan="2">{{ $t('cfds.品种') }}</th>
                                        <th rowspan="2">{{ $t('cfds.指数描述') }}</th>
                                        <th colspan="3">{{ $t('cfds.指数点差') }}</th>
                                        <th rowspan="2" class="fz-17 bg-light">{{ $t('cfds.降幅') }}</th>
                                    </tr>
                                    <tr>
                                        <th class="fz-13">{{ $t('cfds.亚洲时间') }}</th>
                                        <th class="fz-13">{{ $t('cfds.欧洲时间') }}</th>
                                        <th class="fz-13">{{ $t('cfds.美国时间') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>U30USD</td>
                                        <td>{{ $t('cfds.美国道琼斯工业平均指数') }}</td>
                                        <td>1.30</td>
                                        <td>1.30</td>
                                        <td>1.00</td>
                                        <td>
                                            -<span class="number" id="number1">70</span>%<span
                                                class="iconfont icon-down2"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>SPXUSD</td>
                                        <td>{{ $t('cfds.美国标准普尔500指数') }}</td>
                                        <td>0.30</td>
                                        <td>0.31</td>
                                        <td>0.31</td>
                                        <td>
                                            -<span class="number" id="number2">64</span>%
                                            <span class="iconfont icon-down2"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>NASUSD</td>
                                        <td>{{ $t('cfds.美国纳斯达克100指数') }}</td>
                                        <td>0.70</td>
                                        <td>0.70</td>
                                        <td>0.70</td>
                                        <td>
                                            -<span class="number" id="number3">85</span>%<span
                                                class="iconfont icon-down2"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>HSIHKD</td>
                                        <td>{{ $t('cfds.香港恒生指数') }}</td>
                                        <td>6.50+</td>
                                        <td>6.50</td>
                                        <td>6.50</td>
                                        <td>
                                            -<span class="number" id="number4">55</span>%<span
                                                class="iconfont icon-down2"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>CNIUSD</td>
                                        <td>{{ $t('cfds.中国A50指数') }}</td>
                                        <td>6.00+</td>
                                        <td>6.00</td>
                                        <td>6.00</td>
                                        <td>
                                            -<span class="number" id="number5">14</span>%<span
                                                class="iconfont icon-down2"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="btn-box">
                        <a class="btn" type="light" :href="$store.state.mnUrl" rel="nofollow">{{ $t('cfds.开设模拟账户')
                            }}</a>
                        <a class="btn" :href="$store.state.khUrl">{{ $t('cfds.开设真实账户') }}</a>
                    </div>
                </div>

                <div class="session-2 section-box">
                    <div class="session-title " :class="$t('cfds.lang') + '-title'">{{ $t('cfds.产品优势') }}</div>
                    <div class="session-info">
                        {{ $t('cfds.无需持有基础资产2') }}
                    </div>
                    <div class="content">
                        <div class="item">
                            <img class="item-icon" src="@/assets/image/trading-product/section2-3.png" alt="" />
                            <div class="item-content">
                                <div class="item-title">{{ $t('cfds.灵活高杠杆') }}</div>
                                <div class="item-info">
                                    {{ $t('cfds.杠杆高达') }}<br />{{ $t('cfds.放大每笔交易收益') }}
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <img class="item-icon" src="@/assets/image/trading-product/section2-1.png" alt="" />
                            <div class="item-content">
                                <div class="item-title">{{ $t('cfds.实时弹性定价') }}</div>
                                <div class="item-info">
                                    {{ $t('cfds.根据全球市场波动实时调整') }}<br />
                                    {{ $t('cfds.确保您每笔交易享受最优交易条件') }}
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <img class="item-icon" src="@/assets/image/trading-product/section2-2.png" alt="" />

                            <div class="item-content">
                                <div class="item-title">{{ $t('cfds.极致的交易速度') }}</div>
                                <div class="item-info">
                                    {{ $t('cfds.平均执行速度20毫秒') }}<br />{{ $t('cfds.助您抢占市场先机') }}
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <img class="item-icon" src="@/assets/image/trading-product/section2-4.png" alt="" />
                            <div class="item-content">
                                <div class="item-title">{{ $t('cfds.完全透明的交易') }}</div>
                                <div class="item-info">
                                    {{ $t('cfds.无隐藏费用或额外成本') }}<br />{{ $t('cfds.确保您每笔交易的透明和公平') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <a class="btn" type="light" :href="$store.state.mnUrl" rel="nofollow">{{ $t('cfds.开设模拟账户')
                            }}</a>
                        <a class="btn" :href="$store.state.khUrl">{{ $t('cfds.开设真实账户') }}</a>
                    </div>
                </div>
                <div class="session-3 section-box">
                    <div class="session-title " :class="$t('cfds.lang') + '-title'">
                        {{ $t('cfds.全球指数交易深度') }}
                    </div>
                    <c-products></c-products>

                    <div class="btn-box">
                        <a class="btn" href="/zh-cn/AllProducts/">{{ $t('cfds.查看所有产品') }}</a>
                    </div>
                </div>

                <div class="session-4 section-box">
                    <div class="session-title " :class="$t('cfds.lang') + '-title'">
                        {{ $t('cfds.指数差价合约相关问题') }}
                    </div>
                    <div class="content">
                        <div class="vision-item__line d-block d-md-none"></div>
                        <div id="accordionExample" class="accordion">
                            <div id="accordionFlushExample" class="accordion accordion-flush">
                                <div class="accordion-item" v-for="(item, index) in $t('cfds.list')">
                                    <h2 id="flush-heading0" class="accordion-header" @click="changeShow(index + 1)">
                                        <button class="accordion-button collapsed">
                                            {{ item.title }}
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse cont-info"
                                        :class="{ 'showFlag': activeIndex == index + 1 }">
                                        <div class="accordion-body" v-html="item.content">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <a class="btn" type="light" :href="$store.state.mnUrl" rel="nofollow">{{ $t('cfds.开设模拟账户')
                            }}</a>
                        <a class="btn" :href="$store.state.khUrl">{{ $t('cfds.开设真实账户') }}</a>
                    </div>
                </div>

                <div class="public-slogan">
                    <p>
                        {{ $t('cfds.每个认真交易的人') }}<br />Where Your Goals Begin
                    </p>
                </div>
            </div>
            <ss-contact-us mode="all"></ss-contact-us>
        </div>

        <new-footer></new-footer>
    </div>
</template>

<script>
// import '@/assets/js/popper.min.js';
// import '@/assets/js/finpoints.js';
function animateValue(id, start, end, duration) {
    var range = end - start;
    var current = start;
    var increment = end > start ? 1 : -1;
    var stepTime = Math.abs(Math.floor(duration / range));
    var obj = document.getElementById(id);
    if (obj) {
        var timer = setInterval(function () {
            current += increment;
            obj.innerText = current;
            if (current == end) {
                clearInterval(timer);
            }
        }, stepTime);
    }
}
export default {

    data() {
        return {
            activeIndex: 0
        }
    },
    computed: {

    },
    created() {

    },
    mounted() {
        animateValue("number1", 0, 70, 1700);
        animateValue("number2", 0, 64, 1700);
        animateValue("number3", 0, 85, 1700);
        animateValue("number4", 0, 55, 1700);
        animateValue("number5", 0, 14, 1700);
        animateValue("number11", 0, 70, 1700);
        animateValue("number12", 0, 64, 1700);
        animateValue("number13", 0, 85, 1700);
        animateValue("number14", 0, 55, 1700);
        animateValue("number15", 0, 14, 1700);
    },
    methods: {
        changeShow(index) {
            if (index == this.activeIndex) {
                this.activeIndex = 0
            } else {
                this.activeIndex = index
            }
        }
    }
}
</script>

<style lang="scss">
@charset "UTF-8";
@import url("../assets/css/font-family.css");
/* @font-face {
  font-family: "Source Han Serif CN";
  src: url("/ebc-static/fonts/SourceHanSerifCN-Bold 2.otf") format("opentype");
}

@font-face {
  font-family: "Financier Display";
  src: url("/ebc-static/fonts/FinancierDisplay-Bold.otf") format("opentype");
} */

.c-footer .fp-overflow {
    display: unset !important;
}

.showFlag {
    display: block !important;
}

.cn {}

/* .cn .session-title,
.cn .t-sub1 .p1,
.cn .public-slogan,
.cn th {
  font-family: "Source Han Serif CN" !important;
}
.cn td,
.cn .number {
  font-family: Gilroy !important;
}
.cn .btn,
.cn .item-content,
.cn .session-info,
.cn .t-sub1 .p2 {
  font-family: "Source Han Sans CN";
}
.en .session-title,
.en .t-sub1 .p1,
.en .session-2 .content .item .item-title {
  font-family: "Financier Display" !important;
}
.en .t-sub1 .p2,
.en .session-info,
.en th,
.en .btn,
.en .session-2 .content .item .item-info,
.en td,
.en .number {
  font-family: Gilroy !important;
}
.en .public-slogan,
.en .accordion-item {
  font-family: "Source Han Serif CN" !important;
}

.th {
  font-family: "Sukhumvit Set" !important;
}
.th .session-title,
.th .t-sub1 .p1,
.th .session-2 .content .item .item-title,
.th .t-sub1 .p2,
.th .session-info,
.th th,
.th .btn,
.th .session-2 .content .item .item-info,
.th td,
.th .number,
.th .public-slogan,
.th .accordion-item {
  font-family: "Sukhumvit Set" !important;
}

.vi {
  font-family: "Times New Roman" !important;
}
.vi .session-title,
.vi .t-sub1 .p1,
.vi .session-2 .content .item .item-title,
.vi .t-sub1 .p2,
.vi .session-info,
.vi th,
.vi .btn,
.vi .session-2 .content .item .item-info,
.vi td,
.vi .number,
.vi .public-slogan,
.vi .accordion-item {
  font-family: "Times New Roman" !important;
} */

.btn-box .btn {
    font-weight: 300;
}

.btn {
    font-weight: 350;
    display: inline-flex;
    min-width: 240px;
    padding: 0 24px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #5d0101;
    color: #fff;
    border: 1px solid #5d0101;
    transition: background-color 0.3s, color 0.3s, border 0.3;
}

.btn:hover {
    background-color: #af8147;
    border-color: #af8147;
    color: #fff;
}

/* .btn + .btn {
  margin-left: 24px;
} */

.btn[type="light"] {
    background: #fff;
    color: #5d0101;
    border: 1px solid #5d0101;
}

.btn[type="light"]:hover {
    background: #5d0101;
    color: #fff;
}

.section-box {
    padding: 80px 64px;
}

.section-box .content {
    margin: 0 auto;
    width: 1000px;
    padding: 40px 0;
}

.session-1 {
    margin: 0 auto;
    background: #f8f8f8;
}

.session-title {
    color: #333;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.en .session-title {
    font-size: 54px;
}

.session-info {
    margin-top: 24px;
    color: #666;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    line-height: 140%;
    /* 28px */
}

.pc {
    display: block;
}

.mobile {
    display: none;
}

.pc-table {
    width: 100%;
}

.pc-table .icon-down2 {
    display: inline-block;
    font-size: 18px;
    margin-left: 8px;
}

.pc-table thead tr {
    height: 42px;
    background: #949699;
    color: #fff;
}

.bg-light {
    background-color: #730707 !important;
}

.pc-table thead tr th {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    vertical-align: middle;
    padding: 10px 16px;
}

.pc-table thead tr .fz-17 {
    font-size: 17px;
}

.pc-table thead tr .fz-13 {
    font-size: 14px;
}

.pc-table tbody tr {
    /* height: 42px; */
    background: #fff;
    color: #666;
}

.pc-table tbody tr:nth-child(2n) {
    background: #faf8f3;
}

.pc-table tbody tr td {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    vertical-align: middle;
    padding: 10px 16px;
}

.pc-table tbody tr td:first-child {
    color: #333;
}

.pc-table tbody tr td:last-child {
    color: #730707;
    font-size: 18px;
}

.pc-table {
    border-collapse: collapse;
}

.pc-table th {
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.pc-table tr:first-child>th:first-child {
    border-left: none;
}

.pc-table td {
    border-left: 1px solid #f8f8f8;
    border-top: 1px solid #f8f8f8;
}

.pc-table td:first-child {
    border-left: none;
}

.m-table {
    width: 100%;
}

.m-table+.m-table {
    margin-top: 20px;
}

.m-table tr {
    /* height: 42px; */
    color: #666;
    font-size: 12px;
    font-weight: 700;
}

.m-table tr .m-table-td-b {
    display: inline-block;
    font-size: 18px;
    margin-right: 20px;
    margin-bottom: 5px;
    font-weight: 700;
}

.m-table tr .icon-down2 {
    display: inline-block;
    font-size: 22px;
    margin-top: 14px;
    margin-bottom: 4px;
}

.m-table tr .m-table-td-l-2 {
    font-size: 26px;
}

.m-table tr .m-table-td-l-1 {
    color: rgba(255, 255, 255, 0.7);
}

.m-table tr:first-child {
    background-color: #949699;
    color: #fff;
}

.m-table tr:nth-child(2) {
    background-color: #faf8f3;
    color: #666;
}

.m-table tr:nth-child(3) {
    background-color: #fff;
    color: #666;
}

.m-table tr .left {
    text-align: left;
    padding-left: 19px;
    font-weight: 700;
}

.m-table tr td {
    text-align: center;
    vertical-align: middle;
    padding: 10px 16px;
    border-right: 1px solid #f8f8f8;
    /* line-height: 120%; */
}

.session-2 .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    /* padding-left: 56px; */
}

.session-2 .content .item {
    flex-shrink: 0;
    display: flex;
    width: calc(50% - 10px);
    min-height: 164px;
    padding: 32px;
    gap: 24px;
    /* background: #48ba13; */
    transition: all 0.3s;
}

.cn .session-2 .content .item:nth-child(2n) {
    /* transform: translateX(52px); */
    /* margin-left: 40px; */
}

.session-2 .content .item:hover {
    transform: scale(1.05);
    background-color: rgb(175, 129, 71, 0.07);
    border-radius: 10px;
}

.session-2 .content .item .item-icon {
    width: 100px;
    height: 100px;
}

.session-2 .content .item .item-title {
    color: #000;

    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 16px;
}

.session-2 .content .item .item-info {
    color: #666;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
}

.en .session-2 .content .item .item-info {
    font-weight: 300;
}

.session-3 {
    margin: 0 auto;
    background: #f8f8f8;
}

.forex {
    background: url(/ebc-static/image/trade-sub-bg1.jpg) 100% / auto 100% no-repeat;
    height: 450px;
    margin-top: 0px;
    text-align: center;
    padding-top: 126px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.commodity {
    background: url(/ebc-static/image/trade-sub-bg2.jpg) 100% / auto 100% no-repeat;
    height: 450px;
    margin-top: 0px;
    text-align: center;
    padding-top: 126px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.index-cfds {
    background-color: #000104;
    background-image: url(../assets/image/trading-product/index-cfds-bg1.png);
    min-height: 450px;
    margin-top: 0px;
    text-align: center;
    padding-top: 180px;
    padding-bottom: 97px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.share-cfds {
    background: url(/ebc-static/image/trade-sub-bg4.jpg) 100% / auto 100% no-repeat;
    height: 450px;
    margin-top: 0px;
    text-align: center;
    padding-top: 126px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.t-sub1 img {
    margin-top: 26px;
    width: 330px;
    margin-bottom: 14px;
}

.t-sub1 .p1 {
    color: #fff;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.en .t-sub1 .p1 {
    font-size: 90px;
}

.line {
    width: 80px;
    border: 1px solid #d9d9d9;
    margin: 60px auto 50px;
}

.t-sub1 .p2 {
    margin-top: 16px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
}

.en .t-sub1 .p2 {
    font-size: 20px;
}

.t-sub1 .p2 br {
    display: none;
}

.t-sub1 .btn {
    margin-top: 63px;
    display: inline-flex;
    min-width: 180px;
    padding: 0 24px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #fff;
    color: #af8147;
    transition: background-color 0.3s, color 0.3s;
}

.t-sub1 .btn .icon-chevron-right {
    font-size: 24px;
    margin-top: 3px;
}

.t-sub1 .btn:hover {
    background: #af8147;
    color: #fff;
}

.public-title {
    color: #3d0101;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
}

.public-line {
    width: 70px;
    border: 1px solid #af8147;
    margin: 46px auto 36px;
}

.public-title2 {
    color: #3d0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.public-desc {
    width: 54%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
}

.t-sub2 {
    background: #f8f8f8;
    padding: 80px 0;
}

.t-sub2 .row {
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    margin: 46px auto;
    padding: 50px 10% 50px 17%;
    width: 1000px;
}

.t-sub2 .row div {
    margin-top: 10px;
}

.t-sub2 .row img {
    width: 30px;
    position: relative;
    top: 7px;
}

.t-sub2 .row p {
    color: #080e1c;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 16px;
}

.public-btn {
    display: block;
    width: max-content;
    padding: 13px 40px;
    height: 44px;
    font-size: 16px;
    border-radius: 2px;
    color: #3d0101;
    margin: 50px auto 0px;
    text-align: center;
    border: 1px solid #3d0101;
    border-radius: 2px;
}

.public-btn:hover {
    color: #3d0101;
    background: #af8147;
    border: 1px solid #af8147;
}

.btn-box {
    display: flex;
    gap: 24px;
    width: max-content;
    margin: auto;
}

.t-sub3 {
    background: #ffffff;
    padding: 90px 0;
}

.t-sub3 .row {
    margin: 54px auto;
    width: 1200px;
}

.t-sub3 .row div {
    text-align: center;
}

.t-sub3 .row img {
    width: 65px;
    position: relative;
    top: 26px;
}

.t-sub3 .row img {
    width: 90px;
}

.t-sub3 .row .title {
    color: #080e1c;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 48px;
    margin-top: 22px;
}

.t-sub3 .row .desc {
    color: #080e1c;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 0 10%;
}

.t-sub4 {
    background: #f8f8f8;
    padding: 80px 0;
}

.t-sub4 img {
    display: block;
    margin: 40px auto;
    width: 60%;
    background: #fff;
    padding: 30px;
}

.t-sub4 .public-desc {
    width: 60%;
    text-align: left;
}

.t-sub5 {
    background: #ffffff;
    padding: 80px 0;
}

.t-sub5 .t-sub5-img {
    display: block;
    margin: 40px auto;
    width: 1000px;
}

.t-sub5 .public-desc {
    width: 500px;
}

.t-sub5-content {
    position: relative;
    width: 1000px;
    margin: 0 auto;
}

.t-sub5-bottom {
    position: absolute;
    bottom: 0px;
    height: 80px;
    background: rgba(29, 1, 1, 0.75);
    width: 1000px;
    text-align: center;
}

.t-sub5-bottom p {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline;
    padding: 0 60px;
    line-height: 74px;
}

.t-sub5-bottom img {
    width: 7px;
    margin-left: 10px;
}

.t-sub5-bottom span {
    font-size: 26px;
    font-weight: bold;
}

.public-slogan {
    padding: 28px 0px;
    background: #f8f8f8;
    color: #3d0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.t-subtner-box-en .t-sub2 .row {
    padding: 50px 0% 26px 22%;
}

.t-subtner-box-en .t-sub2 .row div {
    width: 100%;
}

.t-subtner-box-en .t-sub1 .p1 {
    line-height: 52px;
    margin-top: 60px;
}

.t-subtner-box-en .public-desc {
    width: 750px;
}

.t-subtner-box-en .t-sub3 .row p {
    margin-left: 10px;
    display: block;
    line-height: 26px;
    margin-top: 30px;
}

.t-subtner-box-en .t-sub1 .p2 {
    letter-spacing: 0px;
    width: 718px;
    line-height: 30px;
}

.t-subtner-box-en .t-sub2 .row p {
    letter-spacing: 0px;
}

.t-sub1 .btn-ug {
    width: 190px;
}

.t-sub5 .container {
    max-width: 1100px;
}

.t-sub5 .row div {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}

.t-sub5 .row img {
    width: 80%;
}

.t-sub5 .row p {
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 90%;
    margin: 30px auto 0;
}

.t-sub6 {
    background: #f8f8f8;
    padding: 80px 0;
}

.t-sub6 .container {
    max-width: 1100px;
}

.t-sub6 .row div {
    background: #fff;
    width: auto;
    margin: 10px 2% 20px;
    height: 44px;
    padding: 14px 20px 14px 46px;
    position: relative;
    border-radius: 3px;
}

.t-sub6 .row div img {
    width: 18px;
    position: absolute;
    top: 13px;
    left: 20px;
}

.t-sub6 .row div p {
    float: left;
    font-size: 16px;
}

.t-sub1 .p1 br {
    display: none;
}

.en-style .t-sub1 .p2 {
    letter-spacing: 0px;
}

.en-style .t-sub1 .p1 {
    margin-top: 80px;
}

.vi-box .t-sub1 .p1 {
    margin-top: 40px;
}

.public-link {
    background: #3d0101;
    /* padding: 11px 28px; */
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    padding: 15px 29px;
    float: left;
    margin-top: 20px;
}

.public-link img {
    width: 7px;
    margin-left: 12px;
}

.public-link:hover {
    color: #ffffff;
    background: #af8147;
}

.public-link2 {
    padding: 14px 28px;
    color: #3d0101;
    font-size: 16px;
    border: 1px solid #3d0101;
    margin-right: 12px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    float: left;
    margin-top: 20px;
}

.public-link2 img {
    width: 7px;
    margin-left: 12px;
}

.public-link2:hover {
    color: #ffffff;
    background: #3d0101;
    border: 1px solid #3d0101;
}

.arrow2 {
    display: none !important;
}

.public-link2:hover .arrow1 {
    display: none !important;
}

.public-link2:hover .arrow2 {
    display: inline-block !important;
}

.product {
    /* width: 1000px; */
    /* margin: 80px auto; */
}

.product .accordion-header {
    cursor: pointer;
}

.product .table-list {
    width: 100%;
    border: none;
    position: relative;
    flex-direction: inherit;
    background: #949699;
}

.product .table-list .flex-1 {
    font-weight: 700;
    line-height: 40px;
    color: #ffffff;
    font-size: 14px;
    padding: 2px 12px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    cursor: pointer;
    text-align: center;
}

.product .table-list .flex-1.active {
    background-color: #3d0101;
    color: #ffffff;
}

.product-box__nav {
    /* margin-bottom: 4px; */
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    color: #666666;
    padding: 0 12px;
    background-color: #f8f8f8;
    padding: 4px 0;
}

.product-box__nav div {
    line-height: 40px;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}

.product-box__accordion-item {
    background: #f7f7f7;
    border: none;
}

.product-box__accordion-item .accordion-button {
    padding: 10px;
    font-size: 14px;
    background: #ffffff;
    color: #333;
}

.product-box__accordion-item .accordion-body-head {
    width: 100%;
    padding: 12px 0;
    background-color: #949699;
    color: #f7f7f7;
}

.product-box__accordion-item .accordion-body-head div {
    flex: 1;
}

.product-box__accordion-header {
    margin-bottom: 0;
}

.product-box .accordion-body-tr {
    width: 100%;
    padding: 12px 0;
}

.product-box .accordion-body-tr div {
    flex: 1;
    text-align: center;
}

.product-box .accordion-body-tr div:nth-of-type(2) {
    color: #48ba13;
}

.product-box .accordion-body-tr .red {
    color: #cc3221 !important;
}

.product-box .accordion-body-tr div:nth-of-type(5) {
    color: #48ba13;
}

.product .look-all {
    display: flex;
    justify-content: center;
    color: #8b8b8b;
    padding: 20px 0;
    font-size: 16px;
}

.product .look-all span {
    padding-top: 5px;
    border-top: 1px solid #8b8b8b;
}

.product .c-underline__top:after {
    background: #7d1614;
    height: 4px;
}

.accordion-body {
    font-size: 14px;
    background: #f8f8f8;
}

.page-screen-3 {
    background-color: #fff;
    position: relative;
    padding-top: 20px;
    padding-bottom: 160px;
}

.session-4 .content {
    /* width: 100%;
  max-width: 882px;
  margin: 22px auto 0; */
}

.page-screen-3 {
    width: 100%;
    max-width: 882px;
    margin: 22px auto 0;
}

.session-4 .content .accordion,
.page-screen-3 .accordion {
    padding: 20px 0 0px;
}

.session-4 .content .accordion-item,
.page-screen-3 .accordion-item {
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    border: 0 solid #d9d9d9;
    margin-bottom: 25px;
}

.session-4 .content .accordion-item:hover,
.page-screen-3 .accordion-item:hover {
    box-shadow: 0 12px 18px -6px rgba(34, 56, 101, 0.12);
}

.session-4 .content .accordion-button,
.page-screen-3 .accordion-button {
    font-size: 18px;
    line-height: 1.5;
    color: #333 !important;
    font-weight: 400;
}

.session-4 .content .accordion-button:focus,
.session-4 .content .accordion-button:not(.collapsed),
.page-screen-3 .accordion-button:focus,
.page-screen-3 .accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: #fff;
}

.session-4 .content .accordion .cont-info,
.page-screen-3 .accordion .cont-info {
    display: none;
}

.session-4 .content .accordion .cont-info.active,
.page-screen-3 .accordion .cont-info.active {
    display: block;
}

.session-4 .content .accordion .accordion-body,
.page-screen-3 .accordion .accordion-body {
    line-height: 34px;
    font-size: 16px;
    color: #585858;
    background: #fff;
}

.session-4 .content .accordion .accordion-body ::v-deep p,
.page-screen-3 .accordion .accordion-body ::v-deep p {
    padding-bottom: 13px;
}

.session-4 .content .accordion .accordion-body ::v-deep a,
.page-screen-3 .accordion .accordion-body ::v-deep a {
    color: #2ea3f2;
}

.session-4 .content .accordion-item,
.page-screen-3 .accordion-item {
    border: none;
}

.rotate .accordion-button:after {
    transform: rotate(180deg);
}

.page-screen-3 .page-title {
    text-align: center;
}

.page-screen-3 .leaf-left,
.page-screen-3 .leaf-right {
    position: absolute;
    top: 0;
    right: 0;
}

.page-screen-3 .leaf-left img,
.page-screen-3 .leaf-right img {
    width: 650px;
    display: block;
}

.page-screen-3 .leaf-left {
    left: -70px;
    right: auto;
}

.en-style2 .t-sub1 .p2 {
    letter-spacing: 0px;
}

.en-style2 .t-sub3 .row .title {
    letter-spacing: 0px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 14px;
}

.en-style2 .t-sub3 .row .desc {
    letter-spacing: 0px;
}

.en-style2 .public-link2 {
    letter-spacing: 0px;
}

.en-style2 .public-link {
    letter-spacing: 0px;
}

/* @media (max-width: 1400px) {
  .t-sub1 .p1 {
    font-size: 34px;
  }
  .t-sub1 .p2 {
    line-height: 32px;
    font-size: 16px;
    width: 600px;
  }
  .t-sub4 img {
    width: 700px;
  }
  .t-sub5 .t-sub5-img {
    width: 700px;
  }
  .t-sub5-bottom {
    width: 700px;
    height: 68px;
  }
  .t-sub5-content {
    width: 700px;
  }
  .t-sub5-bottom p {
    line-height: 60px;
  }
  .t-subtner-box-en .t-sub1 {
    height: 650px;
  }
} */

@media (max-width: 1200px) {
    .section-box {
        padding: 80px 40px;
    }

    .section-box .content {
        margin: 0 auto;
        width: 800px;
        padding: 40px 0;
    }
}

@media (max-width: 995px) {
    .pc {
        display: none;
    }

    .mobile {
        display: block;
    }

    .cn .session-2 .content .item:nth-child(2n) {
        transform: translateX(0);
        /* margin-left: 40px; */
    }

    .btn-box {
        flex-direction: column;
    }

    .session-title {
        font-size: 40px;
    }

    .en .session-title {
        font-size: 46px;
    }

    .session-info {
        margin-top: 15px;
        font-size: 16px;
    }

    .en .session-info {
        font-size: 14px;
        font-weight: 500;
    }

    .section-box {
        padding: 80px 25px;
    }

    .section-box .content {
        margin: 0 auto;
        width: 100%;
        padding: 70px 0 50px;
        row-gap: 40px;
        column-gap: 28px;
    }

    .session-2 .content .item {
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        gap: 14px;
        padding: 0;
        width: calc(50% - 14px);
    }

    .session-2 .content .item .item-icon {
        width: 80px;
        height: 80px;
    }

    .session-2 .content .item .item-title {
        text-align: center;
        margin-bottom: 10px;
        font-size: 18px;
    }

    .session-2 .content .item .item-info {
        text-align: center;
        font-size: 12px;
    }

    .forex {
        background: url(/ebc-static/image/trade-sub-bg1-m.jpg) 100% / auto 100% no-repeat;
        height: 550px;
        margin-top: 0px;
        text-align: center;
        padding-top: 126px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .commodity {
        background: url(/ebc-static/image/trade-sub-bg2-m.jpg) 100% / auto 100% no-repeat;
        height: 550px;
        margin-top: 0px;
        text-align: center;
        padding-top: 126px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .index-cfds {
        padding-top: 130px;
        padding-bottom: 60px;
        /* background: url(/ebc-static/image/trade-sub-bg3-m.jpg) 100% / auto 100%
      no-repeat; */
        /* height: 550px; */
        /* margin-top: 0px;
    text-align: center;
    padding-top: 126px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */
    }

    .share-cfds {
        background: url(/ebc-static/image/trade-sub-bg4-m.jpg) 100% / auto 100% no-repeat;
        height: 550px;
        margin-top: 0px;
        text-align: center;
        padding-top: 126px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .t-sub1 .p1 br {
        display: block !important;
    }

    .t-sub2 .row {
        width: 90%;
        padding: 20px 0% 0px 13%;
        margin: 30px auto;
    }

    .t-sub2 .row p {
        font-size: 14px;
    }

    .t-sub2 .row div {
        margin-top: 0px;
    }

    .t-sub3 .row {
        width: 100%;
        padding: 19px 0% 0px 0%;
        margin: 10px auto 30px;
        background: #ffffff;
    }

    .t-sub3 .row div {
        width: 50%;
        margin-bottom: 30px;
    }

    .t-sub3 .row img {
        width: 90px;
    }

    .public-link2 {
        padding: 14px 18px;
        margin-top: 0px;
        text-align: center;
        width: 100%;
    }

    .public-link {
        padding: 15px 19px;
        margin-top: 0px;
        width: 100%;
        text-align: center;
    }

    .t-sub3 .row .title {}

    .t-sub3 .row .desc {
        font-size: 15px;
        padding: 0 0%;
        line-height: 26px;
    }

    .t-sub5-content {
        width: 100%;
    }

    .t-sub5 .t-sub5-img {
        width: 100%;
    }

    .t-sub5-bottom {
        width: 100%;
        height: 46px;
    }

    .t-sub5-bottom p {
        line-height: 42px;
        font-size: 14px;
        padding: 0px 20px;
    }

    .t-sub5-bottom span {
        font-size: 18px;
    }

    .t-sub5-bottom img {
        width: 6px;
        margin-left: 6px;
    }

    .t-sub1 .p2 {
        padding: 16px 25px 68px;
        font-size: 18px;
        font-style: normal;
        font-weight: 350;
        line-height: 140%;
    }

    .en .t-sub1 .p2 {
        font-size: 14px;
        font-weight: 500;
    }

    .t-sub1 .p2 br {
        display: block;
    }

    .line {
        width: 62px;
        border: 1px solid #d9d9d9;
        margin: 26px auto 0px;
    }

    .t-sub1 .p1 {
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        padding: 0 25px;
    }

    .en .t-sub1 .p1 {
        font-size: 64px;
    }

    .t-sub1 .btn {
        /* margin: 14px auto; */
        margin-top: 0;
    }

    .public-desc {
        width: 90%;
        font-size: 15px;
        line-height: 24px;
    }

    .t-sub4 .public-desc,
    .t-sub5 .public-desc {
        width: 90%;
    }

    .t-sub2,
    .t-sub3,
    .t-sub4,
    .t-sub5 {
        padding: 56px 0;
    }

    .public-line {
        width: 50px;
        margin: 40px auto 0px;
    }

    .public-title {
        font-size: 26px;
    }

    .t-sub2 .row img {
        width: 28px;
    }

    .t-sub4 img {
        width: 90%;
    }

    .public-slogan {
        padding: 20px 20px;
        line-height: 28px;
    }

    .t-subtner-box-en .t-sub2 .row {
        padding: 20px 0% 0px 3%;
    }

    .t-subtner-box-en .t-sub2 .row div {
        width: 100%;
        margin-top: 14px;
    }

    .t-subtner-box-en .t-sub2 .row div p {
        line-height: 30px;
    }

    .t-subtner-box-en .t-sub1 .p1 {
        line-height: 44px;
        font-size: 28px;
        width: 90%;
        margin: 20px auto 0;
    }

    .public-title2 {
        line-height: 26px;
    }

    .t-subtner-box-en .t-sub5-bottom p {
        font-size: 12px;
        letter-spacing: 0px;
        padding: 0px 8px;
    }

    .t-subtner-box-en .t-sub5-bottom span {
        font-size: 16px;
    }

    .t-subtner-box-en .public-desc {
        width: 90%;
    }

    .t-subtner-box-en .t-sub1 .p2 {
        width: 100%;
        font-size: 14px;
        line-height: 26px;
    }

    .t-subtner-box-en .t-sub2 .row {
        width: 100%;
    }

    .t-subtner-box-en .t-sub2 .row img {
        width: 24px;
    }

    .t-subtner-box-en .t-sub1 .p1 {
        font-size: 26px;
        line-height: 34px;
    }

    .t-subtner-box-en .public-title {
        font-size: 22px;
        line-height: 32px;
    }

    .t-subtner-box-en .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }

    .t-subtner-box-en .t-sub2 .row div p {
        line-height: 30px;
        margin-left: 6px;
    }

    .t-subtner-box-en .t-sub2 .row div {
        text-align: center;
    }

    .t-subtner-box-en .t-sub3 .row p {
        margin-left: 8px;
        display: block;
        line-height: 18px;
        margin-top: 8px;
        font-size: 12px;
    }

    .public-slogan {
        padding: 28px 0px;
        line-height: 28px;
        font-size: 13px;
    }

    .t-sub1 .p1 br {
        display: block;
    }

    .t-sub4 img {
        padding: 0;
        background: none;
        margin: 0 auto;
        width: 96%;
    }

    .public-btn {
        margin: 40px auto 0px;
    }

    .t-sub4 .public-desc {
        margin-top: 30px;
    }

    .t-sub5 .row div {
        width: 50%;
        margin-bottom: 0px;
    }

    .t-sub5 .row p {
        font-size: 14px;
        width: 82%;
        margin: 20px auto 0;
        line-height: 22px;
    }

    .t-sub5 .row img {
        width: 88%;
    }

    .t-sub6 .row div p {
        font-size: 14px;
    }

    .t-sub6 .row div {
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 16px 10px 14px 36px;
    }

    .t-sub6 .row div img {
        top: 13px;
        left: 12px;
    }

    .t-sub6 {
        padding-bottom: 50px;
    }

    .en-style .t-sub6 .row div {
        width: 92%;
        line-height: 22px;
        height: auto;
        padding: 14px 10px 12px 36px;
    }

    .en-style .t-sub6 .row div p {
        font-size: 14px;
    }

    .en-style .t-sub6 .row div img {
        top: 18px;
        left: 12px;
        width: 13px;
    }

    .en-style .public-title {
        font-size: 23px;
    }

    .en-style .t-sub1 .p1 {
        line-height: 46px;
        font-size: 30px;
        margin-top: 55px;
    }

    .product {
        width: 100%;
    }

    .product .table-list .flex-1:nth-child(4),
    .product .table-list .flex-1:nth-child(5) {
        display: none;
    }

    .accordion-body {
        padding: 0;
    }

    .product-box__nav {
        font-size: 12px;
    }

    .product-box__accordion-item .accordion-button {
        font-size: 12px;
    }

    .accordion-body {
        font-size: 12px;
    }

    .session-4 .content .accordion .accordion-body,
    .page-screen-3 .accordion .accordion-body {
        padding: 1rem 1.25rem;
    }

    .session-4 .content .accordion,
    .page-screen-3 .accordion {
        padding-top: 0px;
    }

    .product .table-list .flex-1 {
        font-size: 13px;
    }

    .session-4 .content .accordion,
    .page-screen-3 .accordion {
        margin-bottom: 50px;
    }

    .product .accordion-button:after {
        display: none;
    }

    .product-box__accordion-item .accordion-button {
        padding: 8px 0;
    }

    .page-screen-3 {
        padding-bottom: 100px;
    }

    .en-style2 .public-link2 {
        float: unset;
        margin-bottom: 16px;
    }

    .en-style2 .public-link {
        float: unset;
    }

    .en-style2 .t-sub3 .row .title {
        line-height: 24px;
    }

    .en-style2 .t-sub3 .row .desc {
        margin-top: 14px;
    }

    .en-style2 .product {
        margin-top: 30px;
    }

    .en-style2 .public-title {
        padding: 0 8%;
        line-height: 32px;
    }

    .en-style2 .page-screen-3 {
        padding-bottom: 70px;
    }

    .en-style2 .product {
        margin-bottom: 30px;
    }
}

@media (max-width: 400px) {
    .m-table tr td {
        padding: 10px 10px;
    }

    .t-sub6 .row div p {
        font-size: 13px;
    }

    .t-sub6 .row div {
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 14px 10px 12px 34px;
        height: 38px;
    }

    .t-sub6 .row div img {
        top: 13px;
        left: 12px;
        width: 14px;
    }

    .en-style .t-sub6 .row div {
        width: 92%;
        line-height: 22px;
        height: auto;
    }

    .en-style .t-sub6 .row div p {
        font-size: 14px;
    }
}
</style>